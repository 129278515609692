var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "yearly-view" },
    [
      _c(
        "div",
        { staticClass: "event-filter" },
        [
          _c(
            "el-select",
            {
              attrs: {
                size: "small",
                placeholder: "Showing all event types",
                multiple: "",
                clearable: "",
              },
              model: {
                value: _vm.eventFilter,
                callback: function ($$v) {
                  _vm.eventFilter = $$v
                },
                expression: "eventFilter",
              },
            },
            _vm._l(_vm.calendarEventTypes, function (type) {
              return _c(
                "el-option",
                {
                  key: type.type,
                  attrs: { label: type.label, value: type.type },
                },
                [
                  _c("div", { staticClass: "event-type-option" }, [
                    _c("div", {
                      staticClass: "color-preview",
                      style: `background-color: ${type.color}`,
                    }),
                    _c("span", [_vm._v(_vm._s(type.label))]),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("calendar", {
        attrs: {
          "display-header": false,
          "week-start": 1,
          "display-week-number": true,
          "data-source": _vm.filteredEvents,
          "enable-range-selection": true,
          "custom-day-renderer": _vm.handleDayRender,
        },
        on: {
          "mouse-on-day": _vm.handleMouseOnDay,
          "mouse-out-day": _vm.handleMouseOutDay,
          "select-range": _vm.handleSelectRange,
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !!_vm.selectedDay,
              expression: "!!selectedDay",
            },
          ],
          ref: "eventPopup",
          staticClass: "event-popup",
          on: {
            mouseenter: _vm.handlePopperMouseOver,
            mouseleave: _vm.handlePopperMouseLeave,
          },
        },
        [
          _vm.selectedDay
            ? _vm._l(_vm.selectedDay.events, function (event) {
                return _c(
                  "div",
                  {
                    key: event.id,
                    staticClass: "event-item",
                    on: {
                      click: () => {
                        _vm.updateEvent(event)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "event-type",
                        style: `background-color: ${event.color}`,
                      },
                      [_vm._v(" " + _vm._s(event.type.label) + " ")]
                    ),
                    _c("span", { staticClass: "event-description" }, [
                      _vm._v(_vm._s(event.name)),
                    ]),
                    event.project
                      ? _c("span", { staticClass: "event-project" }, [
                          _vm._v(" " + _vm._s(event.project) + " "),
                        ])
                      : _vm._e(),
                  ]
                )
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }